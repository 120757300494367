<script>

export default{
    props:{
        allAdverts:{
            type: Object,
            default: null
        }
    },
    methods:{
        getAdvertFileUrl(fileName){
            if(fileName){
                return '/storage/adverts/' + fileName;
            }
        }
    }
}
</script>

<template>
    <div v-if="allAdverts" class="flex justify-between pb-6 px-6 h-full items-end">
        <template v-for="advert in allAdverts">
            <a v-if="advert['advert_file']" :href="advert['advert_link']" target="_blank" class="w-full h-48 md:h-24  flex justify-center">
                <img class="max-h-full" style="max-height:100%" :src="getAdvertFileUrl(advert['advert_file'])" >
            </a>
        </template>
    </div>
</template>

<style scoped>

</style>
