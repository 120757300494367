<template>
    <div>
      <div v-if="resources && resources.length >0" class="h-36"
           :class="{'hidden': !open || !resources,'h-full': resources.length <=4}"
           style="max-height: 42rem;">

        <LazyList v-if="resources" :key="query"
                  :data="resources"
                  :itemsPerRender="5"
                  defaultLoadingColor="#999999"
        >
          <template v-slot="{item}">
            <div class="flex cursor-pointer text-v3-base-800 dark:text-v3-base-300 hover:text-black dark:hover:text-white my-1 items-center" @click="navigateToItem(item)">
                <template v-if="true">
                  <span class="w-6 h-6 mx-2 center">
                      <template v-if="item.entity.properties.get('type') === 'leave-request'">
                          <svg class="h-6 w-6 shrink-0 text-v3-purple" width="25" height="24" viewBox="0 0 25 24" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M20.8407 3H4.90765C3.81225 3 2.91602 3.9 2.91602 5V19C2.91602 20.1 3.81225 21 4.90765 21H20.8407C21.9361 21 22.8323 20.1 22.8323 19V5C22.8323 3.9 21.9361 3 20.8407 3ZM10.8825 17H5.90346V15H10.8825V17ZM10.8825 13H5.90346V11H10.8825V13ZM10.8825 9H5.90346V7H10.8825V9ZM15.6824 15L12.8742 12.16L14.2783 10.75L15.6824 12.17L18.8391 9L20.2532 10.42L15.6824 15Z"
                                    fill="currentColor"/>
                            </svg>
                      </template>
                        <template v-else-if="item.entity.properties.get('type') === 'base-people' || item.entity.properties.get('type') === 'hq-people'">
                            <svg class="h-6 w-6 shrink-0" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" :class="{'text-v3-purple': item.entity.properties.get('type') === 'base-people'}"
                                 viewBox="0 0 24 24">
                              <path fill="currentColor"
                                    d="M16 17v2H2v-2s0-4 7-4s7 4 7 4m-3.5-9.5A3.5 3.5 0 1 0 9 11a3.5 3.5 0 0 0 3.5-3.5m3.44 5.5A5.32 5.32 0 0 1 18 17v2h4v-2s0-3.63-6.06-4M15 4a3.4 3.4 0 0 0-1.93.59a5 5 0 0 1 0 5.82A3.4 3.4 0 0 0 15 11a3.5 3.5 0 0 0 0-7"/>
                            </svg>
                        </template>
                        <template v-else-if="item.entity.properties.get('type') === 'projects' || item.entity.properties.get('type') === 'hq-project'">
                            <svg class="h-6 w-6 shrink-0 fill-current" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" :class="{'text-v3-purple': item.entity.properties.get('type') === 'projects'}">
                                  <path d="M20.7656 2.625H3.51562C3.10078 2.625 2.76562 2.96016 2.76562 3.375V20.625C2.76562 21.0398 3.10078 21.375 3.51562 21.375H20.7656C21.1805 21.375 21.5156 21.0398 21.5156 20.625V3.375C21.5156 2.96016 21.1805 2.625 20.7656 2.625ZM8.76562 17.4375C8.76562 17.5406 8.68125 17.625 8.57812 17.625H6.70312C6.6 17.625 6.51562 17.5406 6.51562 17.4375V6.5625C6.51562 6.45937 6.6 6.375 6.70312 6.375H8.57812C8.68125 6.375 8.76562 6.45937 8.76562 6.5625V17.4375ZM13.2656 10.875C13.2656 10.9781 13.1813 11.0625 13.0781 11.0625H11.2031C11.1 11.0625 11.0156 10.9781 11.0156 10.875V6.5625C11.0156 6.45937 11.1 6.375 11.2031 6.375H13.0781C13.1813 6.375 13.2656 6.45937 13.2656 6.5625V10.875ZM17.7656 12.5625C17.7656 12.6656 17.6813 12.75 17.5781 12.75H15.7031C15.6 12.75 15.5156 12.6656 15.5156 12.5625V6.5625C15.5156 6.45937 15.6 6.375 15.7031 6.375H17.5781C17.6813 6.375 17.7656 6.45937 17.7656 6.5625V12.5625Z" fill="currentColor"/>
                              </svg>
                        </template>
                        <template v-else-if="item.entity.properties.get('type') === 'payment-voucher'">
                            <svg class="h-6 w-6 shrink-0 fill-current text-v3-pink" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M4 4a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2a2 2 0 0 1-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 1-2-2a2 2 0 0 1 2-2V6a2 2 0 0 0-2-2zm11.5 3L17 8.5L8.5 17L7 15.5zm-6.69.04c.98 0 1.77.79 1.77 1.77a1.77 1.77 0 0 1-1.77 1.77c-.98 0-1.77-.79-1.77-1.77a1.77 1.77 0 0 1 1.77-1.77m6.38 6.38c.98 0 1.77.79 1.77 1.77a1.77 1.77 0 0 1-1.77 1.77c-.98 0-1.77-.79-1.77-1.77a1.77 1.77 0 0 1 1.77-1.77"/></svg>
                        </template>
                        <template v-else-if="item.entity.properties.get('type') === 'qa'">
                          <svg class="h-6 w-6 shrink-0 fill-current text-v3-orange" width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M14.97 11H14.18L13.9 10.73C14.9139 9.55402 15.4711 8.05269 15.47 6.5C15.47 4.77609 14.7852 3.12279 13.5662 1.90381C12.3472 0.684819 10.6939 0 8.97 0C5.55 0 2.75 2.64 2.5 6H4.52C4.77 3.75 6.65 2 8.97 2C11.46 2 13.47 4.01 13.47 6.5C13.47 8.99 11.46 11 8.97 11C8.8 11 8.64 10.97 8.47 10.95V12.97C8.64 12.99 8.8 13 8.97 13C10.58 13 12.06 12.41 13.2 11.43L13.47 11.71V12.5L18.47 17.49L19.96 16L14.97 11Z" fill="currentColor"/>
                              <path d="M3.58497 13L0.5 9.99576L1.72876 8.79915L3.57625 10.5983L7.27124 7L8.5 8.19661L3.58497 13Z" fill="currentColor"/>
                          </svg>
                        </template>
                        <template v-else-if="item.entity.properties.get('type') === 'qa-tracker'">
                            <svg class="h-6 w-6 shrink-0 fill-current text-v3-orange" width="21" height="20" viewBox="0 0 21 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                              <path d="M17.57 2.93L16.16 4.34C17.6586 5.84245 18.5001 7.87793 18.5 10C18.5 14.42 14.92 18 10.5 18C6.08 18 2.5 14.42 2.5 10C2.5 5.92 5.55 2.56 9.5 2.07V4.09C6.66 4.57 4.5 7.03 4.5 10C4.5 13.31 7.19 16 10.5 16C13.81 16 16.5 13.31 16.5 10C16.5 8.34 15.83 6.84 14.74 5.76L13.33 7.17C14.05 7.9 14.5 8.9 14.5 10C14.5 12.21 12.71 14 10.5 14C8.29 14 6.5 12.21 6.5 10C6.5 8.14 7.78 6.59 9.5 6.14V8.28C8.9 8.63 8.5 9.26 8.5 10C8.5 11.1 9.4 12 10.5 12C11.6 12 12.5 11.1 12.5 10C12.5 9.26 12.1 8.62 11.5 8.28V0H10.5C4.98 0 0.5 4.48 0.5 10C0.5 15.52 4.98 20 10.5 20C16.02 20 20.5 15.52 20.5 10C20.5 7.24 19.38 4.74 17.57 2.93Z" fill="currentColor"/>
                          </svg>
                        </template>
                      <template v-else-if="item.entity.properties.get('type') === 'order'">
                          <svg class="h-6 w-6 shrink-0 mx-auto text-v3-green" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2s-.9-2-2-2M1 2v2h2l3.6 7.59l-1.35 2.45c-.16.28-.25.61-.25.96c0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12l.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49A1.003 1.003 0 0 0 20 4H5.21l-.94-2zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2s2-.9 2-2s-.9-2-2-2"/></svg>

                        </template>
                      <template v-else-if="item.entity.properties.get('type') === 'rental'">
                          <svg class="h-6 w-6 shrink-0 mx-auto text-v3-green" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M4 21q-1.25 0-2.125-.875T1 18q0-.65.25-1.237T2 15.75V11h2V5h8l4.7 11.075q.15.35.225.7T17 17.5q0 1.45-1.025 2.475T13.5 21q-1.025 0-1.888-.537T10.326 19h-3.5q-.325.9-1.1 1.45T4 21m14-1V4h2v14h3v2zM4 19q.425 0 .713-.288T5 18t-.288-.712T4 17t-.712.288T3 18t.288.713T4 19m9.5 0q.625 0 1.063-.437T15 17.5t-.437-1.062T13.5 16t-1.062.438T12 17.5t.438 1.063T13.5 19m-4.575-5h4.725l-2.975-7H6v4z"/></svg>

                        </template>
                  </span>
              </template>
                <template v-else>
                    <span class="w-6 h-6 mx-2 center">

                    </span>
                </template>


                <template v-if="item.entity.properties.get('type') === 'leave-request'">
                  Leave Request ->
              </template>
                <template v-else-if="item.entity.properties.get('type') === 'base-people' || item.entity.properties.get('type') === 'hq-people'">
                    People ->
                </template>
                <template v-else-if="item.entity.properties.get('type') === 'projects' || item.entity.properties.get('type') === 'hq-project'">
                    Projects ->
                </template>
                <template v-else-if="item.entity.properties.get('type') === 'payment-voucher'">
                    Voucher ->
                </template>
                <template v-else-if="item.entity.properties.get('type') === 'qa'">
                    QA ->
                </template>
                <template v-else-if="item.entity.properties.get('type') === 'qa-tracker'">
                    Tracker ->
                </template>
                <template v-else-if="item.entity.properties.get('type') === 'order'">
                    Orders ->
                </template>
                <template v-else-if="item.entity.properties.get('type') === 'rental'">
                    Rentals ->
                </template>
                {{item.entity.properties.get(propertyKey)}}
            </div>
          </template>
        </LazyList>
      </div>
    </div>
</template>
<script>
import CollapsableHeader from "@/v3/components/headers/CollapsableHeader.vue"
import LazyList from "lazy-load-list/vue"

export default {
  name: 'SearchElement',
  components: {CollapsableHeader, LazyList},
    data(){
      return{
        'previousType': null
      }
    },
  props: {
    open: {},
    resources: {
        type: Array,
        default: null
    },
    query: {},
      title: {
        type: String,
          default: null
      },
      propertyKey:{
        type: String,
          default: null
      }
  },
    methods:{
        navigateToItem(item){
            let selfLink = item.entity.links.filter(link => link.rels.contains('self')).first().href;
            if(selfLink.includes('/api')) {
                let parts = selfLink.split('/api');
                this.$router.push(parts[1]);
            }else{
                this.$router.push(selfLink);
            }
        },
        setImage(type)
        {
            if(this.previousType !== type){
                this.previousType = type;
                return true;
            }
            return false;
        }
    }
}
</script>
<style scoped>
@media (max-height: 1000px) {
}
</style>
