<template>
    <div>
        <label v-if="showLabel" id="listbox-label" class="block text-sm font-medium leading-5 text-v3-gray-900 dark:text-white mt-3">
            {{ title }} <span class="text-v3-red" v-if="required">*</span>
        </label>
        <div v-click-outside="hideOptions">
            <div class="relative">
                <button type="button" @click="showOptions = true" class="text-v3-gray-900 text-opacity-70 dark:text-white bg-v3-gray-900 bg-opacity-5 dark:bg-white dark:bg-opacity-5 relative min-h-full w-full rounded-md pl-3 pr-10 p-0.5 text-left cursor-default focus:outline-none sm:text-sm" :class="{'z-20': showOptions, 'bg-gray-200': isDisabled}">
                  <span class="inline-block truncate w-full">
                    {{ selectedValue }}
                  </span>
                    <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <!-- Heroicon name: solid/selector -->
                    <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                  </span>
                </button>
                <br>
                <div v-if="showOptions" class="absolute z-10 bg-v3-white-100 max-h-60 rounded-md text-base focus:outline-none sm:text-sm " >
                    <div class="bg-v3-gray-50 bg-opacity-10 dark:bg-v3-base-700 p-1 h-full w-full">
                        <input :placeholder="'Search ' + title" v-model="searchTerm" class="w-full p-1 bg-v3-gray-900 bg-opacity-5 text-v3-gray-900 text-opacity-70 dark:bg-white dark:text-white dark:text-opacity-70 dark:bg-opacity-5 placeholder-v3-gray-900 placeholder-opacity-70 dark:placeholder-white dark:placeholder-opacity-70 relative border border-gray-300 rounded-b-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-700 focus:border-primary-700 sm:text-sm mb-2" />
                        <ul class="overflow-auto  max-h-56 mb-4" :class="{'h-56': filteredOptions && filteredOptions.length > 14}">

                            <LazyList v-if="filteredOptions"
                                      :data="filteredOptions"
                                      :itemsPerRender="15"
                            >
                                <template v-slot="{item}">
                                    <li  @click="updateValue(item)" class="text-v3-gray-900 text-opacity-70 dark:text-white dark:text-opacity-70 cursor-default select-none relative py-2 pl-3 pr-9 hover:text-white hover:bg-primary-700">
                                        <span class="font-normal block truncate">
                                          {{item.title}}
                                        </span>
                                    </li>
                                </template>
                            </LazyList>

<!--                            <li v-for="option in filteredOptions" @click="updateValue(option)" class="text-v3-gray-900 text-opacity-70 dark:text-white dark:text-opacity-70 cursor-default select-none relative py-2 pl-3 pr-9 hover:text-white hover:bg-primary-700">-->
<!--                                <span class="font-normal block truncate">-->
<!--                                  {{option.title}}-->
<!--                                </span>-->
<!--                            </li>-->

                        </ul>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import LazyList from "lazy-load-list/vue";
export default {
    components: {LazyList},
    directives: {
        clickOutside: vClickOutside.directive
    },
    props: {
        value: {
            type: Number,
            default: null
        },
        options: {
            type: Array,
            default: []
        },
        title: {
            type: String,
            default: null
        },
        required: {
            type: Boolean,
            default: false
        },
        field: {
            type: Array,
            default: null
        },
        dependsOn: {
            type: String,
            default: null
        },
        dependsOnValue: {
            default: null
        },
        showLabel:{
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            searchTerm:'',
            editableValue: this.value,
            showOptions: false,
            selectedValue: this.title
        }
    },
    created() {
        if(this.field !== null){
            this.options = this.field.options;
            this.title = this.field.title;
            this.selectedValue = this.field.title;
            if(this.field.value){
                this.selectedValue = (this.options.filter((option) => {
                    return option.value === this.field.value || option.value === parseInt(this.field.value);
                }, this)[0].title);
            }
            if(this.field.validation != null) {
                this.required =  this.field.validation.includes('required')
            }
        }
    },
    computed: {
        filteredOptions() {
            if (this.dependsOn !== null && this.dependsOnValue === null) {
                return;
            }

            let options = this.options.filter((option) => {
                if(this.searchTerm === ""){
                    return true;
                }
                return option.title.toLowerCase().includes(this.searchTerm.toLowerCase());
            }, this);

            if (this.dependsOn === null || this.dependsOnValue === null) {
                console.log('empty');
                return options;
            }

            options = options.filter((option) => {
                console.log(parseInt(this.dependsOnValue));
                return option.client_id === this.dependsOnValue
                    || this.dependsOnValue.toString().split(',').includes((option[this.dependsOn]).toString())
            })
            return options;
        },
        isDisabled() {
            return this.dependsOn !== null && this.dependsOnValue === null;
        },
    },
    methods: {
        updateValue(option) {
            this.$emit('input', option.value);
            this.hideOptions();
            this.selectedValue = option.title;
            this.searchTerm = '';
        },
        hideOptions(){
            this.showOptions = false;
        },
    },
}
</script>

<style scoped>

</style>
