<template>
    <div class="rounded-md flex-grow">
        <div class="focus-within:z-20">
            <label for="email" class="block text-sm font-medium leading-5 text-v3-gray-900 dark:text-white mt-3" v-if="showLabel">{{field.title}}<span v-if="isOptional" class="text-v3-gray-900 text-opacity-50 dark:text-white dark:text-opacity-50"> (Optional)</span><span class="text-v3-red ml-1" v-if="required">*</span></label>
            <div>
                <select
                    :aria-label="field.title"
                    :name="field.name"
                    :class="{'is-invalid' : errors.has(validationFieldName), 'bg-gray-200' : isDisabled, 'text-gray-400' : propsVal === null, [selectDarkColor]: selectDarkColor}"
                    class="p-1 bg-v3-gray-900 bg-opacity-5 text-v3-gray-900 text-opacity-70 placeholder-v3-gray-900 placeholder-opacity-70 dark:text-white dark:text-opacity-70 dark:placeholder-white dark:placeholder-opacity-70 form-select shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-0 rounded-md"
                    v-model="propsVal"
                    @change="onChange"
                >
                    <option v-if="includeNullOption" :value="null">{{field.title}}</option>
                    <template v-for="option in filteredOptions">

                        <option :key="option.value" :value="option.value" :data-extraDetails="option.payment_type || option.account_used">{{option.title}}</option>
                    </template>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            value: {},
            field: {
                type: Object
            },
            validationFieldName: {
                type: String,
                default: 'random'
            },
            dependsOn: {
                type: String,
                default: null
            },
            dependsOnValue: {
                default: null
            },
            isOptional: {
                type: Boolean,
                default: false
            },
            dependsOn2: {
                type: String,
                default: null
            },
            dependsOnValue2: {
                default: null
            },
            includeNullOption:{
                type: Boolean,
                default: true
            },
            showLabel:{
                type: Boolean,
                default: true
            },
            selectDarkColor:{
                type: String,
                default: 'dark:bg-v3-gray-950'
            }
        },
        data() {
            return {
                propsVal: this.value,
                selectedOptions: null
            }
        },
        computed: {
            filteredOptions() {
                if (this.dependsOn !== null && this.dependsOnValue === null) {
                    return;
                }

                if (this.dependsOn === null || this.dependsOnValue === null) {
                    return this.field.options;
                }
                let options = this.field.options;
                options = options.filter(function (option) {
                    return option[this.dependsOn] !== null && (option[this.dependsOn]).toString() === (this.dependsOnValue).toString() ||
                        (Array.isArray(option[this.dependsOn]) && ((option[this.dependsOn]).includes(parseInt(this.dependsOnValue)) ||
                            this.checkOptionsContainsDependsArrayValue(option[this.dependsOn], this.dependsOnValue)
                            ))
                    || this.dependsOnValue.toString().split(',').includes((option[this.dependsOn]).toString())


                }.bind(this));
                if(this.dependsOn2 === null || this.dependsOnValue2 === null){
                    return options;
                }

                options = options.filter(function (option) {
                    return option[this.dependsOn2] !== null && (option[this.dependsOn2]).toString() === (this.dependsOnValue2).toString() ||
                        (Array.isArray(option[this.dependsOn2]) && ((option[this.dependsOn2]).includes(parseInt(this.dependsOnValue2)) ||
                            this.checkOptionsContainsDependsArrayValue(option[this.dependsOn2], this.dependsOnValue2)
                        ))
                        || this.dependsOnValue2.toString().split(',').includes((option[this.dependsOn2]).toString())


                }.bind(this));

                return options;

            },
            isDisabled() {
                return this.dependsOn !== null && this.dependsOnValue === null;
            },
            required: function(){
                if(this.field.validation !== null && this.field.validation !== undefined) {
                    return this.field.validation.includes('required');
                }
                return false;
            },
        },
        methods:{
            checkOptionsContainsDependsArrayValue(dependsOnOption, dependsOnValue){
                let bool = false;
               dependsOnOption.forEach(function(value){
                    if(Array.isArray(dependsOnValue) && dependsOnValue && value && dependsOnValue.split(',').includes(value.toString())){
                        bool = true;
                    }
                }.bind(this))
                return bool;
            },
            onChange(event) {
                this.$emit('returnPaymentType', event.target.options[event.target.options.selectedIndex].dataset.extradetails);
            },
            setValue(value){
                this.propsVal = value;
            }
        },
        watch: {
            propsVal: {
                handler(value) {
                    this.$emit("input", value);
                },
                deep: true
            },
            value: {
                handler(value) {
                    this.propsVal = value;
                },
                deep: true
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
