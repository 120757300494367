import Dashboard from "@/v3/pages/Dashboard.vue";
const routes = [
    {///
        path: '/notifications',
        components: {
            default: () =>  import(/* webpackChunkName: "Notifications" */ "./pages/Notifications")
        },
        meta: {
            title: 'Notifications'
        }
    },
    {
        path: '/orders/:id',
        name: 'singleOrder',
        component: () =>  import(/* webpackChunkName: "order" */ "@/v3/pages/Order.vue"),
        meta: {
            title: 'Order'
        }
    },
    {
        path: '/orders',
        component: () =>  import(/* webpackChunkName: "order" */ "@/v3/pages/Orders.vue"),
        meta: {
            title: 'Orders'
        }
    },
    {
        path: '/schedule-manager',
        name:'ScheduleManager',
        component: () =>  import(/* webpackChunkName: "schedule-manager" */ "@/v3/pages/ScheduleManager.vue"),
        meta: {
            title: 'Schedule Manager'
        }
    },
    {
        path: '/sages',
        component: () =>  import(/* webpackChunkName: "sage-tax-codes" */ "@/v3/pages/SageTaxCodes.vue"),
        meta: {
            title: 'Sages'
        }
    },
    {
        path: '/sages_description',
        component: () =>  import(/* webpackChunkName: "sage-descriptions" */ "@/v3/pages/SageDescriptions.vue"),
        meta: {
            title: 'Sage Descriptions'
        }
    },
    {
        path: '/sages_nominal_code',
        component: () =>  import(/* webpackChunkName: "sage-nominal-codes" */ "@/v3/pages/SageNominalCodes.vue"),
        meta: {
            title: 'Sage Nominal Codes'
        }
    },
    {
        path: '/invoices',
        component: () =>  import(/* webpackChunkName: "invoices" */ "@/v3/pages/Invoices.vue"),
        meta: {
            title: 'Invoices'
        }
    },
    {
        path: '/credit-notes',
        component: () =>  import(/* webpackChunkName: "credit-notes" */ "@/v3/pages/CreditNotes.vue"),
        meta: {
            title: 'Credit Notes'
        }
    },
    {///
        path: '/invoices/:id',
        component: () =>  import(/* webpackChunkName: "invoice" */ "@/v3/pages/Invoice.vue"),
        meta: {
            title: 'Invoice'
        }
    },
    // {
    //     path: '/suppliers/:id',
    //     component: () =>  import(/* webpackChunkName: "contact" */ "@/v2/pages/Contact.vue"),
    //     meta: {
    //         title: 'Address Book'
    //     }
    // },
    // {
    //     path: '/suppliers',
    //     component: () =>  import(/* webpackChunkName: "address-book" */ "@/v2/pages/AddressBook.vue"),
    //     meta: {
    //         title: 'Contact'
    //     }
    // },
    // {
    //     path: '/client-address-book',
    //     component: () =>  import(/* webpackChunkName: "client-address-book" */ "@/v2/pages/ClientAddressBook.vue"),
    //     meta: {
    //         title: 'Client Address Book'
    //     }
    // },
    {
        path: '/invoices-in',
        component: () =>  import(/* webpackChunkName: "purchase-invoices" */ "@/v3/pages/PurchaseInvoices.vue"),
        meta: {
            title: 'Purchase Invoices'
        }
    },
    {
        path: '/clients/:id/companies',
        component:   () => import(/* webpackChunkName: "suppliers" */ "@/v3/pages/Suppliers.vue"),
        meta: {
            title: 'Suppliers'
        }
    },
    {
        path: '/companies/:id',
        component:  () => import(/* webpackChunkName: "supplier" */ "@/v3/pages/Supplier.vue"),
        meta: {
            title: 'Supplier'
        }
    },
    {
        path: '/base/projects/:id',
        component: () => import(/* webpackChunkName: "project" */ "@/v3/pages/Project.vue"),
        props:{
            app: 'base'
        },
        meta: {
            title: 'Project'
        }
    },
    {
        path: '/hq/projects/:id',
        component: () => import(/* webpackChunkName: "project" */ "@/v3/pages/Project.vue"),
        props:{
            app: 'hq'
        },
        meta: {
            title: 'Project'
        }
    },
    {
        path: '/clients',
        component: () => import(/* webpackChunkName: "clients" */ "@/v3/pages/Clients.vue"),
        meta: {
            title: 'Clients'
        }
    },
    {///
        path: '/clients/:id',
        component: () => import(/* webpackChunkName: "client" */ "@/v3/pages/Client.vue"),
        meta: {
            title: 'Clients'
        }
    },
    {
         path: '/direct-suppliers/:id',
        component: () => import(/* webpackChunkName: "direct-suppliers" */ "@/pages/DirectSuppliers.vue"),
        meta:{
            title: 'Direct Supplier'
         }
    },
    {
        path: '/direct-suppliers',
        component: () => import(/* webpackChunkName: "direct-supplier" */ "@/pages/IndividualItem/DirectSupplier.vue"),
        meta:{
            title: 'Direct Suppliers'
        }
    },
    {
        path: '/users/:id',
        component: () => import(/* webpackChunkName: "user" */ "@/v3/pages/User"),
        meta: {
            title: 'User'
        }
    },
    // {
    //     path: '/default-client-users',
    //     name:'DefaultClientUsers',
    //     component: () => import(/* webpackChunkName: "default-users" */ "@/pages/Collections/DefaultClientUsers.vue"),
    //     meta: {
    //         title: 'Users'
    //     }
    // },
    // {///
    //     path: '/return-requests',
    //     component: () => import(/* webpackChunkName: "return-requests" */ "@/v2/pages/ReturnRequests.vue"),
    //     meta: {
    //         title: 'return-requests'
    //     }
    // },
    // {///
    //     path: '/return-requests/:id',
    //     component: () => import(/* webpackChunkName: "return-request" */ "@/v2/pages/ReturnRequest.vue"),
    //     meta: {
    //         title: 'return-request'
    //     }
    // },
    {
        path: '/take-off-orders',
        name:'TakeOffs',
        component: () => import(/* webpackChunkName: "orders" */ "@/v3/pages/Orders.vue"),
        props:{
            title: "Take offs"
        },
        meta: {
            title: 'Take Off Orders',
        },
    },
    // {
    //     path: '/take-off-orders/search',
    //     components: {
    //         default: TakeOffsSearchV2
    //     },
    //     meta: {
    //         title: 'Search Take Offs'
    //     }
    // },
    {
        path: '/quotes-mismatch',
        name:'MismatchQuotes',
        component: () => import(/* webpackChunkName: "orders" */ "@/v3/pages/Orders.vue"),
        props:{
            title: "Quotes Mismatch"
        },
        meta: {
            title: 'Quotes Mismatch'
        }
    },
    {
        path: '/tender-orders',
        name:'TenderOrders',
        component: () => import(/* webpackChunkName: "orders" */ "@/v3/pages/Orders.vue"),
        meta: {
            title: 'Tender Orders'
        }
    },
    {///
        path: '/rfis',
        components: {
            default: () => import(/* webpackChunkName: "rfis" */ "@/v2/pages/RFIs.vue"),
        },
        meta: {
            title: 'RFIs'
        }
    },

    // {
    //     path: '/quality-assessments/create',
    //     components: {
    //         default: () => import(/* webpackChunkName: "create-qa" */ "@/v2/pages/CreateQa.vue"),
    //     },
    //     meta: {
    //         title: 'Create a QA'
    //     }
    // },
    {
        path: '/quality-assessments',
        components: {
            default: () => import(/* webpackChunkName: "qas" */ "@/v3/pages/Qas.vue"),
        },
        meta: {
            title: 'QAs'
        }
    },
    {
        path: '/quality-assessments/:id',
        name: 'quality-assessment',
        components: {
            default: () => import(/* webpackChunkName: "qa" */ "@/v3/pages/Qa.vue"),
        },
        meta: {
            title: 'QAs'
        }
    },
    // {
    //     path: '/qa-categories/create',
    //     components: {
    //         default: () => import(/* webpackChunkName: "create-qa-category" */ "@/v2/pages/CreateQaCategory.vue")
    //     },
    //     meta: {
    //         title: 'Create Qa Category'
    //     }
    // },
    {
        path: '/qa-categories',
        components: {
            default: () => import(/* webpackChunkName: "qa-categories" */ "@/v3/pages/QaCategories.vue")
        },
        meta: {
            title: 'Qa Categories'
        }
    },
    {///
        path: '/qa-categories/:id',
        components:{
            default: () => import(/* webpackChunkName: "qa-category" */ "@/v3/pages/QaCategory.vue")
        },
        meta:{
            title: 'Qa Category'
        }
    },
    // {
    //     path: '/pre-checks/create',
    //     components: {
    //         default: () => import(/* webpackChunkName: "create-pre-check" */ "@/v2/pages/CreatePreCheck.vue"),
    //     },
    //     meta: {
    //         title: 'Create a pre-check'
    //     }
    // },
    {
        path: '/pre-checks',
        components: {
            default: () => import(/* webpackChunkName: "pre-checks" */ "@/v3/pages/PreChecks.vue"),
        },
        meta: {
            title: 'Pre-Checks'
        }
    },
    {///
        path: '/pre-checks/:id',
        name: 'pre-check',
        components: {
            default: () => import(/* webpackChunkName: "pre-check" */ "@/v3/pages/PreCheck.vue"),
        },
        meta: {
            title: 'Pre-Checks'
        }
    },
    {
        path: '/qa-trackers',
        components: {
            default: () => import(/* webpackChunkName: "qa-trackers" */ "@/v3/pages/QaTrackers.vue"),
        },
        meta: {
            title: 'QA Trackers'
        }
    },{
        path: '/qa-trackers/:id',
        components: {
            default: () => import(/* webpackChunkName: "qa-tracker" */ "@/v3/pages/QaTracker.vue"),
        },
        meta: {
            title: 'QA Tracker'
        }
    },
    // {
    //     path: '/qa-dash',
    //     components: {
    //         default: () => import(/* webpackChunkName: "qa-dashboard" */ "@/v3/pages/QaDashboard.vue")
    //     },
    //     meta: {
    //         title: 'QA Dash'
    //     }
    // },
    // {///
    //     path: '/vehicles',
    //     components: {
    //         default: () => import(/* webpackChunkName: "vehicles" */ "@/v2/pages/Vehicles.vue"),
    //     },
    //     meta: {
    //         title: 'Vehicles'
    //     }
    // },
    // {
    //     path: '/vehicles/:id',
    //     components: {
    //         default: () => import(/* webpackChunkName: "vehicle" */ "@/v2/pages/Vehicle.vue"),
    //     },
    //     meta: {
    //         title: 'Vehicle'
    //     }
    // },
    // {///
    //     path: '/office-items',
    //     components: {
    //         default: () => import(/* webpackChunkName: "office-items" */ "@/v2/pages/OfficeItems.vue"),
    //     },
    //     meta: {
    //         title: 'Office Items'
    //     }
    // },
    // {
    //     path: '/office-items/:id',
    //     components: {
    //         default: () => import(/* webpackChunkName: "office-item" */ "@/v2/pages/OfficeItem.vue"),
    //     },
    //     meta: {
    //         title: 'Office Item'
    //     }
    // },
    // {///
    //     path: '/materials',
    //     components: {
    //         default: () => import(/* webpackChunkName: "materials" */ "@/v2/pages/Materials.vue"),
    //     },
    //     meta: {
    //         title: 'Materials'
    //     }
    // },
    // {
    //     path: '/materials/:id',
    //     components: {
    //         default: () => import(/* webpackChunkName: "material" */ "@/v2/pages/Material.vue"),
    //     },
    //     meta: {
    //         title: 'Material'
    //     }
    // },
    {
        path: '/rental-items',
        components: {
            default: () => import(/* webpackChunkName: "rental-items" */ "@/v3/pages/RentalItems.vue"),
        },
        meta: {
            title: 'Rental Items'
        }
    },
    {
        path: '/payment-vouchers',
        components: {
            default: () => import(/* webpackChunkName: "payment-vouchers" */ "@/v3/pages/PaymentVouchers.vue"),
        },
        meta: {
            title: 'Payment Vouchers'
        }
    },
    {///
        path: '/payment-vouchers/:id',
        components: {
            default: () => import(/* webpackChunkName: "voucher" */ "@/v3/pages/Voucher.vue"),
        },
        meta: {
            title: 'Payment Voucher'
        }
    },
    {///
        path: '/slip-send',
        components: {
            default: () => import(/* webpackChunkName: "slip-send" */ "@/v3/pages/SlipSend.vue"),
        },
        meta: {
            title: 'Slip Send'
        }
    },
    {///
        path: '/slip-send/pending/:id',
        components: {
            default: () => import(/* webpackChunkName: "pending-slip-send" */ "@/v2/pages/PendingPayNSend.vue"),
        },
        meta: {
            title: 'Slip Send - Pending'
        }
    },
    {///
        path: '/rental-items/:id',
        components: {
            default: () => import(/* webpackChunkName: "rental-item" */ "@/v3/pages/RentalItem.vue"),
        },
        meta: {
            title: 'Rental Item'
        }
    },
    {///
        path: '/request-for-information/:id',
        components: {
            default: () => import(/* webpackChunkName: "rfi" */ "@/v2/pages/Rfi.vue")
        },
        meta: {
            title: 'RFI'
        }
    },
    {///
        path: '/admin/cms-urls',
        components: {
            default: () => import(/* webpackChunkName: "cms-news" */ "@/v2/pages/CmsNews.vue")
        },
        meta: {
            title: 'CMS URLs'
        }
    },
    {///
        path: '/rfis/search',
        components: {
            default: () => import(/* webpackChunkName: "rfi-search" */ "@/pages/RFISearch.vue")
        },
        meta: {
            title: 'Search RFIs'
        }
    },
    {///
        path: '/procure-reports',
        components: {
            default: () => import(/* webpackChunkName: "export-orders" */ "@/v3/pages/ProcurementExports.vue")
        },
        meta: {
            title: 'Procure Reports'
        }
    },
    {///
        path: '/invoice-reports',
        components: {
            default: () => import(/* webpackChunkName: "export-invoices" */ "@/v2/pages/ExportInvoices.vue")
        },
        meta: {
            title: 'Invoice Export'
        }
    },
    {
        path: '/manufacturer-reports',
        components: {
            default: () => import(/* webpackChunkName: "manufacturer-reports" */ "@/pages/ManufacturerReports.vue")
        },
        meta: {
            title: 'Manufacturer Reports'
        }
    },
    {///
        path: '/holiday-requests',
        component: () => import(/* webpackChunkName: "holiday-requests" */ "@/v3/pages/LeaveRequests.vue"),
        meta: {
            title: 'Holiday Requests'
        }
    },
    // {
    //     path: '/dashboard-widgets-create',
    //     components: {
    //         default:  () => import(/* webpackChunkName: "dashboard-widget-edit" */ "@/pages/DashboardWidgetEdit.vue")
    //     },
    //     meta: {
    //         title: 'Create Default Widget'
    //     }
    // },
    // {
    //     path: '/dashboard-widgets/:id',
    //     component:  () => import(/* webpackChunkName: "dashboard-widget-edit" */ "@/pages/DashboardWidgetEdit.vue"),
    //     meta: {
    //         title: 'DefaultWidget'
    //     }
    // },
    //
    // {
    //     path: '/dashboard-widgets',
    //     components: {
    //         default:  () => import(/* webpackChunkName: "dashboard-widgets" */ "@/pages/Collections/DashboardWidgets.vue")
    //     },
    //     meta: {
    //         title: 'Widgets'
    //     }
    // },
    {///
        path: '/on-site-id',
        components: {
            default:  () => import(/* webpackChunkName: "my-on-site-id" */ "@/v2/pages/MyOnSiteId.vue")
        },
        meta: {
            title: 'On Site Id'
        }
    },
    {///
        path: '/on-site-id-batch',
        components: {
            default:  () => import(/* webpackChunkName: "onsite-id-batch" */ "@/v2/pages/OnSiteIdBatch.vue")
        },
        meta: {
            title: 'Download On Site Id'
        }
    },
    {///
        path: '/on-site-id/:id',
        name: 'ViewOnSiteId',
        components: {
            default:  () => import(/* webpackChunkName: "onsite-id-single" */ "@/v2/pages/OnSiteIdSingle.vue")
        },
        meta: {
            title: 'On Site Id'
        }
    },
    {
        path: '/mobile/dashboard',
        name: 'OnSiteIdDashboard',
        components: {
            default:  () => import(/* webpackChunkName: "onsite-id-dashboard" */ "@/v2/pages/OnSiteIdDashboard.vue")
        },
        meta: {
            title: 'Dashboard'
        }
    },
    {
        path: '/mobile/users/:id',
        name: 'MobileUser',
        components: {
            default:  () => import(/* webpackChunkName: "mobile-user" */ "@/v2/pages/MobileUser.vue")
        },
        meta: {
            title: 'User'
        }
    },
    {
        path: '/request-client-access',
        name: 'MobileEmployerSettings',
        components: {
            default:  () => import(/* webpackChunkName: "mobile-employer-settings" */ "@/v2/pages/MobileEmployerSettings.vue")
        },
        meta: {
            title: 'Employer Settings'
        }
    },
    {///
        path: '/quote-generator',
        components: {
            default:  () => import(/* webpackChunkName: "quote-generator" */ "@/v3/pages/QuoteGenerator.vue")
        },
        meta: {
            title: 'Quote Generator'
        }
    },

    {///
        path: '/traccar',
        components: {
            default:  () => import(/* webpackChunkName: "traccar" */ "@/v2/pages/Traccar.vue")
        },
        meta: {
            title: 'Traccar'
        }
    },
    {///
        path: '/call-outs',
        components: {
            default: () => import(/* webpackChunkName: "callouts" */ "@/v2/pages/CallOuts.vue")
        },
        meta: {
            title: 'Call Outs'
        }
    },
    {///
        path: '/call-outs/:id',
        components: {
            default: () => import(/* webpackChunkName: "Callout" */ "@/v2/pages/CallOutSingle.vue")
        },
        meta: {
            title: 'Call Outs'
        }
    },
    {
        path: '/signup',
        name:'SignUp',
        components: {
            default: () => import(/* webpackChunkName: "sign-up" */ "@/pages/AppSignUp.vue")
        },
        meta: {
            title: 'Sign up'
        }
    },
    {
        path: '/signup/success',
        name: 'SignUpSuccess',
        components: {
            default: () => import(/* webpackChunkName: "sign-up-success" */ "@/pages/SignUpSuccess.vue")
        },
        meta: {
            title: 'Sign up - success'
        }
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        components: {
            default: () => import(/* webpackChunkName: "forgot-password" */ "@/v3/pages/ForgotPassword.vue")
        },
        meta: {
            title: 'Reset Password'
        }
    },{
        path: '/osid-forgot-password',
        name: 'OnSiteIdForgotPassword',
        components: {
            default: () => import(/* webpackChunkName: "onsite-forgot-password" */ "@/pages/OnSiteIdForgotPassword.vue")
        },
        meta: {
            title: 'Reset Password'
        }
    },
    {
        path: '/reset-password/:token/:id',
        name: 'ResetPassword',
        components: {
            default: () => import(/* webpackChunkName: "reset-password" */ "@/v3/pages/ResetPassword.vue")
        },
        meta: {
            title: 'Reset Password'
        }
    },
    {
        path: '/register',
        name: 'Register',
        components: {
            default: () => import(/* webpackChunkName: "register" */ "@/v3/pages/Register.vue")
        },
        meta: {
            title: 'Sign Up'
        }
    },
    {
        path: '/onboarding/:id',
        name: 'Onboarding',
        components: {
            default: () => import(/* webpackChunkName: "onboarding-notlogged" */ "@/v3/pages/Onboarding-notlogged.vue")
        },
        meta: {
            title: 'Onboarding'
        }
    },
    {
        path: '/osid-register',
        name: 'OnSiteIdRegister',
        components: {
            default: () => import(/* webpackChunkName: "onsite-register" */ "@/pages/OnSiteIdRegister.vue")
        },
        meta: {
            title: 'Sign Up'
        }
    },
    {
        path: '/induction',
        name:'Induction',
        components: {
            default: () => import(/* webpackChunkName: "induction" */ "@/pages/Induction.vue")
        },
        meta: {
            title: 'Registration'
        }
    },
    {
        path: '/login',
        name: 'Login',
        components: {
            default: () => import(/* webpackChunkName: "login" */ "@/v3/pages/Login.vue")
        },
        meta: {
            title: 'Login to CMS'
        }
    },
    {
        path: '/osid-login',
        name: 'OnSiteIdLogin',
        components: {
            default: () => import(/* webpackChunkName: "onsite-login" */ "@/pages/OnSiteIdLogin.vue")
        },
        meta: {
            title: 'Login to CMS'
        }
    },
    {
        path: '/rav/dashboard/invoices/add',
        name: 'RAV',
        components:{
            default: () => import(/* webpackChunkName: "hub-viewer" */ "@/pages/HubViewer.vue")
        },
        props:{
            default: route => ({ baseUrl: "https://rav.companiesms.co.uk/dashboard/invoices/add" })
        },
        meta: {
            title: 'RAV'
        }
    },
    {
        path: '/rav/dashboard/cc/view',
        name: 'RAV',
        components:{
            default: () => import(/* webpackChunkName: "hub-viewer" */ "@/pages/HubViewer.vue")
        },
        props:{
            default: route => ({ baseUrl: "https://rav.companiesms.co.uk/dashboard/cc/view" })
        },
        meta: {
            title: 'RAV'
        }
    },
    {
        path: '/rav/dashboard/vouchers/manage/office',
        name: 'RAV',
        components:{
            default: () => import(/* webpackChunkName: "hub-viewer" */ "@/pages/HubViewer.vue")
        },
        props:{
            default: route => ({ baseUrl: "https://rav.companiesms.co.uk/dashboard/vouchers/manage/office" })
        },
        meta: {
            title: 'RAV'
        }
    },
    {
        path: '/rav/dashboard/employees/view',
        name: 'RAV',
        components:{
            default: () => import(/* webpackChunkName: "hub-viewer" */ "@/pages/HubViewer.vue")
        },
        props:{
            default: route => ({ baseUrl: "https://rav.companiesms.co.uk/dashboard/employees/view" })
        },
        meta: {
            title: 'RAV'
        }
    },
    {
        path: '/oss/admin/project',
        name: 'OSS',
        components:{
            default: () => import(/* webpackChunkName: "hub-viewer" */ "@/pages/HubViewer.vue")
        },
        props:{
            default: route => ({ baseUrl: "https://oss.companiesms.co.uk/admin/project" })
        },
        meta: {
            title: 'OSS'
        }
    },
    {
        path: '/oss/admin/user',
        name: 'OSS',
        components:{
            default: () => import(/* webpackChunkName: "hub-viewer" */ "@/pages/HubViewer.vue")
        },
        props:{
            default: route => ({ baseUrl: "https://oss.companiesms.co.uk/admin/user" })
        },
        meta: {
            title: 'OSS'
        }
    },
    {
        path: '/oss/admin/weekly-summary',
        name: 'OSS',
        components:{
            default: () => import(/* webpackChunkName: "hub-viewer" */ "@/pages/HubViewer.vue")
        },
        props:{
            default: route => ({ baseUrl: "https://oss.companiesms.co.uk/admin/weekly-summary" })
        },
        meta: {
            title: 'OSS'
        }
    },
    {
        path: '/oss/admin/daily-summary',
        name: 'OSS',
        components:{
            default: () => import(/* webpackChunkName: "hub-viewer" */ "@/pages/HubViewer.vue")
        },
        props:{
            default: route => ({ baseUrl: "https://oss.companiesms.co.uk/admin/daily-summary" })
        },
        meta: {
            title: 'OSS'
        }
    },
    {
        path: '/oss/admin/timesheet-builder',
        name: 'OSS',
        components:{
            default: () => import(/* webpackChunkName: "hub-viewer" */ "@/pages/HubViewer.vue")
        },
        props:{
            default: route => ({ baseUrl: "https://oss.companiesms.co.uk/admin/timesheet-builder" })
        },
        meta: {
            title: 'OSS'
        }
    },
    {
        path: '/help-hub/cms',
        name: 'CMS Help Hub',
        components:{
            default: () => import(/* webpackChunkName: "hub-viewer" */ "@/pages/HubViewer.vue")
        },
        props:{
            default: route => ({ baseUrl: "https://cmshelp.companiesms.co.uk" })
        },
        meta: {
            title: 'CMS Help Hub'
        }
    },
    {
        path: '/help-hub/oss',
        name: 'OSS Help',
        components:{
            default: () => import(/* webpackChunkName: "hub-viewer" */ "@/pages/HubViewer.vue")
        },
        props:{
            default: route => ({ baseUrl: "https://help.companiesms.co.uk/oss" })
        },
        meta: {
            title: 'OSS Help'
        }
    },
    {
        path: '/help-hub/rav',
        name: 'RAV Help',
        components:{
            default: () => import(/* webpackChunkName: "hub-viewer" */ "@/pages/HubViewer.vue")
        },
        props:{
            default: route => ({ baseUrl: "https://help.companiesms.co.uk/rav" })
        },
        meta: {
            title: 'RAV Help Hub'
        }
    },
    {
        path: '/urls/cms_news',
        name: 'cms-news',
        components:{
            default: () => import(/* webpackChunkName: "hub-viewer" */ "@/pages/CMSNewsViewer.vue")

        },
        meta: {
            title: 'CMS News'
        }
    },
    {
        path: '/urls/elmech_drive',
        name: 'elmech-drive',
        components:{
            default: () => import(/* webpackChunkName: "hub-viewer" */ "@/pages/CMSNewsViewer.vue")
        },
        meta: {
            title: 'Elmech Drive'
        }
    },
    {
        path: '/urls/cms_drive',
        name: 'cms-drive',
        components:{
            default: () => import(/* webpackChunkName: "hub-viewer" */ "@/pages/CMSNewsViewer.vue")
        },
        meta: {
            title: 'CMS Drive'
        }
    },
    {
        path: '/urls/cctv',
        name: 'cctv',
        components:{
            default: () => import(/* webpackChunkName: "hub-viewer" */ "@/pages/CMSNewsViewer.vue")
        },
        meta: {
            title: 'CCTV'
        }
    },
    {
        path: '/urls/ezpz_pdf',
        name: 'ezpz-pdf',
        components:{
            default: () => import(/* webpackChunkName: "hub-viewer" */ "@/pages/CMSNewsViewer.vue")
        },
        meta: {
            title: 'EzPz PDF'
        }
    },
    {
        path: '/batch-invoice',
        name: 'Batch Invoice',
        components:{
            default: () => import(/* webpackChunkName: "hub-viewer" */ "@/v3/pages/IframeViewer.vue")
        },
        props:{
            default: route => ({
                baseUrl: "https://invoice-compiler.companiesms.co.uk/hw.html?v=1.0.5",
                title: 'Batch Invoice',
                darkLogo: process.env.VUE_APP_API_URL + '/api/json/lottie?type=batch_invoice_dark',
                lightLogo: process.env.VUE_APP_API_URL + '/api/json/lottie?type=batch_invoice_light'
            })
        },
        meta: {
            title: 'Batch Invoice'
        }
    },
    {
        path: '/',
        name:'Dashboard',
        components: {
             default: () => import(/* webpackChunkName: "dashboard" */ "@/v3/pages/Dashboard.vue")
        },
        meta: {
            title: 'CMS Dashboard'
        }
    },
    {
        path: '/costing',
        name:'Costing',
        components: {
            default: () => import(/* webpackChunkName: "costing" */ "@/v3/pages/Costings.vue")

        },
        meta: {
            title: 'CMS Costing'
        }
    },
    {
        path: '/onsiteselfie/daily-reports',
        name: 'Daily Report',
        group: 'OSS',
        components: {
            default: () => import(/* webpackChunkName: "daily-report" */ "@/v3/pages/DailyReport.vue")
        },
        meta:{
            title: 'Daily Report'
        }
    },{
        path: '/on-site-selfie/weekly-reports',
        name: 'Weekly Report',
        components: {
            default: () => import(/* webpackChunkName: "weekly-report" */ "@/v3/pages/WeeklyReport.vue")
        },
        meta:{
            title: 'Weekly Report'
        }
    },
    {
        path: '/on-site-selfie/timesheet-builder',
        name: 'Timesheet Builder',
        components: {
            default: () => import(/* webpackChunkName: "timesheet-builder" */ "@/v3/pages/TimesheetEditor.vue")
        },
        meta:{
            title: 'Timesheet Builder'
        }
    },
    {
        path: '/on-site-selfie/custom-reports',
        name: 'Custom Reports',
        components: {
            default: () => import(/* webpackChunkName: "custom-reports" */ "@/v3/pages/OssCustomReports.vue")
        },
        meta:{
            title: 'Custom Reports'
        }
    },
    // {
    //     path: '/site-vehicle-entry-logs',
    //     name: 'Vehicle Logs',
    //     components: {
    //         default: () => import(/* webpackChunkName: "vehicle-logs" */ "@/v2/pages/VehicleLogs.vue")
    //     },
    //     meta:{
    //         title: 'Vehicle Logs'
    //     }
    // },
    // {
    //     path: '/site-vehicle-entry-logs/:id',
    //     name: 'Vehicle Logs',
    //     components: {
    //         default: () => import(/* webpackChunkName: "vehicle-log" */ "@/v2/pages/VehicleLog.vue")
    //     },
    //     meta:{
    //         title: 'Vehicle Log'
    //     }
    // },
    // {
    //     path: '/clients/:id/site-vehicle-entry-log-requirements',
    //     name: 'Site Vehicle Entry Log Requirements',
    //     components: {
    //         default: () => import(/* webpackChunkName: "vehicle-log-requirements" */ "@/v2/pages/SiteVehicleLogRequirements.vue")
    //     },
    //     meta:{
    //         title: 'Site Vehicle Entry Log Requirements'
    //     }
    // },
    // {
    //     path: '/clients/:id/on-site-selfie',
    //     name: 'On-Site Selfie',
    //     components: {
    //         default: () => import(/* webpackChunkName: "client-onsite-selfie" */ "@/v2/pages/ClientOnSiteSelfie.vue")
    //     },
    //     meta:{
    //         title: 'On-Site Selfie'
    //     }
    // },
    // {
    //     path: '/clients/:id/quality-assessments',
    //     name: 'Quality Assessments',
    //     components: {
    //         default: () => import(/* webpackChunkName: "client-qa" */ "@/v2/pages/ClientQualityAssessments.vue")
    //     },
    //     meta:{
    //         title: 'QualityAssessment'
    //     }
    // },
    // {
    //     path: '/clients/:id/reoccurring-invoice-items',
    //     name: 'Reoccurring Invoice Items',
    //     components: {
    //         default: () => import(/* webpackChunkName: "client-reoccurring-invoice-items" */ "@/v2/pages/ClientReoccurringInvoiceItems.vue")
    //     },
    //     meta:{
    //         title: 'Reoccurring Invoice Items'
    //     }
    // },
    // {
    //     path: '/clients/:id/contracts',
    //     name: 'Contracts',
    //     components: {
    //         default: () => import(/* webpackChunkName: "client-contracts" */ "@/v2/pages/ClientContracts.vue")
    //     },
    //     meta:{
    //         title: 'Contracts'
    //     }
    // },
    // {
    //     path: '/clients/:id/departments',
    //     name: 'Departments',
    //     components: {
    //         default: () => import(/* webpackChunkName: "client-departments" */ "@/v2/pages/ClientDepartments.vue")
    //     },
    //     meta:{
    //         title: 'Departments'
    //     }
    // },
    // {
    //     path: '/clients/:id/projects',
    //     name: 'Projects',
    //     components: {
    //         default: () => import(/* webpackChunkName: "client-projects" */ "@/v2/pages/ClientProjects.vue")
    //     },
    //     meta:{
    //         title: 'Projects'
    //     }
    // },
    // {
    //     path: '/clients/:id/users',
    //     name: 'Users',
    //     components: {
    //         default: () => import(/* webpackChunkName: "client-users" */ "@/v2/pages/ClientUsers.vue")
    //     },
    //     meta:{
    //         title: 'Users'
    //     }
    // },
    // {
    //     path: '/clients/:id/user-groups',
    //     name: 'User Groups',
    //     components: {
    //         default: () => import(/* webpackChunkName: "client-user-groups" */ "@/v2/pages/ClientUserGroups.vue")
    //     },
    //     meta:{
    //         title: 'User Groups'
    //     }
    // },
    // {
    //     path: '/clients/:id/settings',
    //     name: 'Settings',
    //     component: () => import(/* webpackChunkName: "client-settings" */ "@/v2/pages/ClientSettingsTools.vue"),
    //     meta: {
    //         title: 'Settings'
    //     }
    // },
    // {
    //     path: '/clients/:id/procurement',
    //     name: 'Procurement Settings',
    //     component: () => import(/* webpackChunkName: "client-procurement" */ "@/v2/pages/ClientProcurementTools.vue"),
    //     meta: {
    //         title: 'Procurement Settings'
    //     }
    // },
    // {
    //     path: '/clients/:id/supplier-groups',
    //     component: () => import(/* webpackChunkName: "supplier-groups" */ "@/v2/pages/SupplierGroups.vue"),
    //     meta: {
    //         title: 'Supplier Groups'
    //     }
    // },
    // {
    //     path: '/clients/:id/bookkeeping',
    //     name: 'Bookkeeping',
    //     component: () => import(/* webpackChunkName: "client-bookkeeping" */ "@/v2/pages/ClientBookkeepingTools.vue"),
    //     meta: {
    //         title: 'Bookkeeping'
    //     }
    // },
    // {
    //     path: '/clients/:id/bookkeeping/order-report',
    //     name: 'Export Orders',
    //     component: () => import(/* webpackChunkName: "export-client-orders" */ "@/v2/pages/ExportClientOrders.vue"),
    //     meta: {
    //         title: 'Export Orders'
    //     }
    // },
    // {
    //     path: '/clients/:id/bookkeeping/invoice-report',
    //     name: 'Invoices Export',
    //     component: () => import(/* webpackChunkName: "export-client-invoices" */ "@/v2/pages/ExportClientInvoices.vue"),
    //     meta: {
    //         title: 'Invoices Export'
    //     }
    // },
    // {
    //     path: '/clients/:id/account',
    //     name: 'Account',
    //     component: () => import(/* webpackChunkName: "client-account" */ "@/v2/pages/ClientAccount.vue"),
    //     meta: {
    //         title: 'Account'
    //     }
    // },
    // {
    //     path: '/clients/:id/day-pay-requests',
    //     name: 'Day Pay Requests',
    //     components: {
    //         default: () => import(/* webpackChunkName: "client-day-pay-requests" */ "@/v2/pages/ClientDayPayRequests.vue")
    //     },
    //     meta:{
    //         title: 'Day Pay Requests'
    //     }
    // },
    // {
    //     path: '/clients/:id/worxs',
    //     name: 'WorXs',
    //     components: {
    //         default: () => import(/* webpackChunkName: "worxs" */ "@/v2/pages/ClientPreChecks.vue")
    //     },
    //     meta:{
    //         title: 'WorXs'
    //     }
    // },
    {
        path: '/day-pay-requests',
        name: 'DayPayRequests',
        components: {
            default: () => import(/* webpackChunkName: "day-pay-requests" */ "@/v3/pages/DayPayRequests.vue")
        },
        meta:{
            title: 'Day Pay Requests'
        }
    },
    {
        path: '/day-pay-requests/:id',
        name: 'DayPayRequest',
        components: {
            default: () => import(/* webpackChunkName: "day-pay-request" */ "@/v3/pages/DayPayRequest.vue")
        },
        meta:{
            title: 'Day Pay Requests'
        }
    },
    {
        path: '/subscriptions',
        name: 'Subscriptions',
        components: {
            default: () => import(/* webpackChunkName: "subscriptions" */ "@/v2/pages/Subscriptions.vue")
        },
        meta:{
            title: 'Subscriptions'
        }
    },
    {
        path: '/subscriptions/:id',
        name: 'Subscription',
        components: {
            default: () => import(/* webpackChunkName: "subscription" */ "@/v2/pages/Subscription.vue")
        },
        meta:{
            title: 'Subscriptions'
        }
    },
    {
        path: '/product-library',
        name: 'ProductLibrary',
        components: {
            default: () => import(/* webpackChunkName: "product-library" */ "@/v2/pages/ProductLibrary.vue")
        },
        meta:{
            title: 'Product Library'
        }
    },
    {
        path: '/product-library/:id',
        name: 'ProductSingle',
        components: {
            default: () => import(/* webpackChunkName: "product-library-single" */ "@/v2/pages/ProductSingle.vue")
        },
        meta:{
            title: 'Product Library'
        }
    },
    {
        path: '/product-categories',
        name: 'ProductCategories',
        components: {
            default: () => import(/* webpackChunkName: "product-categories" */ "@/v2/pages/ProductCategories.vue")
        },
        meta:{
            title: 'Categories'
        }
    },
    {
        path: '/product-categories/:id',
        name: 'CategorySingle',
        components: {
            default: () => import(/* webpackChunkName: "product-category" */ "@/v2/pages/Category.vue")
        },
        meta:{
            title: 'Categories'
        }
    },
    {
        path: '/product-categories/:id/no-type',
        name: 'ProductsWithoutType',
        components: {
            default: () => import(/* webpackChunkName: "products-without-type" */ "@/v2/pages/ProductsWithoutType.vue")
        },
        meta:{
            title: 'Products - no type'
        }
    },
    {
        path: '/product-library/types/:id',
        name: 'ProductTypeSingle',
        components: {
            default: () => import(/* webpackChunkName: "product-type-single" */ "@/v2/pages/ProductTypeSingle.vue")
        },
        meta:{
            title: 'Product Library - types'
        }
    },
    {
        path: '/clients/:id/pending-users',
        name: 'Pending Users',
        components: {
            default: () => import(/* webpackChunkName: "client-pending-users" */ "@/v3/pages/ClientPendingUsers.vue")
        },
        meta:{
            title: 'Pending Users'
        }
    },
    {
        path: '/accept-terms-and-conditions',
        name:'Terms And Conditions',
        component: () => import(/* webpackChunkName: "terms" */ "@/pages/TermsAndConditions.vue"),
        meta: {
            title: 'Terms And Conditions'
        }
    },
    {
        path: '/terms-not-accepted',
        name:'Terms Not Accepted',
        component: () => import(/* webpackChunkName: "terms-not-accepted" */ "@/pages/TermsAndConditionsNotAccepted.vue"),
        meta: {
            title: 'Terms And Conditions Not Accepted'
        }
    },
    {
        path: '/files/quotes/:fileName',
        component: () => import(/* webpackChunkName: "download-pdf" */ "@/pages/DownloadPdf.vue"),
        props:{
            url: process.env.VUE_APP_API_URL + '/api' + window.location.pathname
        },
        meta: {
            title: 'Download Quote'
        }
    },
    {
        path: '/files/invoices/:fileName',
        component: () => import(/* webpackChunkName: "download-pdf" */ "@/pages/DownloadPdf.vue"),
        props:{
            url: process.env.VUE_APP_API_URL + '/api' + window.location.pathname
        },
        meta: {
            title: 'Download Invoice'
        }
    },
    {
        path: '/files/rfis/:fileName',
        component: () => import(/* webpackChunkName: "download-pdf" */ "@/pages/DownloadPdf.vue"),
        props:{
            url: process.env.VUE_APP_API_URL + '/api' + window.location.pathname
        },
        meta: {
            title: 'Download RFI'
        }
    },
    {
        path: '/files/attachments/:fileName',
        component: () => import(/* webpackChunkName: "download-pdf" */ "@/pages/DownloadPdf.vue"),
        props:{
            url: process.env.VUE_APP_API_URL + '/api' + window.location.pathname
        },
        meta: {
            title: 'Download Attachment'
        }
    },
    {
        path:'/evac',
        components: {
            default: () => import(/* webpackChunkName: "evac" */ "@/v2/pages/Evac.vue"),
        },
        meta: {
            title: 'Evac'
        }
    },
    // {
    //     path:'/hr-dash',
    //     components:{
    //         default: () => import(/* webpackChunkName: "hr-dashboard" */ "@/v3/pages/HrDashboard.vue")
    //     },
    //     meta: {
    //         title: 'HR Dash'
    //     }
    // },
    // {
    //     path:'/hq-dashboard',
    //     components:{
    //         default: () => import(/* webpackChunkName: "hq-dashboard" */ "@/v3/pages/HqDashboard.vue")
    //     },
    //     meta: {
    //         title: 'HR Dash'
    //     }
    // },
    {
        path:'/hr-people',
        components:{
            default: () => import(/* webpackChunkName: "hr-people" */ "@/v3/pages/HrPeople.vue")
        },
        meta: {
            title: 'People'
        }
    },
    {
        path:'/hq-people',
        components:{
            default: () => import(/* webpackChunkName: "hq-people" */ "@/v3/pages/HqPeople.vue")
        },
        meta: {
            title: 'HQ People'
        }
    },
    {
        name: 'hr-projects',
        path:'/base-projects',
        components:{
            default: () => import(/* webpackChunkName: "base-projects" */ "@/v3/pages/BaseProjects.vue")
        },
        meta: {
            title: 'Projects'
        }
    },
    {
        name: 'hq-projects',
        path:'/hq-projects',
        components:{
            default: () => import(/* webpackChunkName: "base-projects" */ "@/v3/pages/BaseProjects.vue")
        },
        meta: {
            title: 'Projects'
        }
    },
    {
        path: '/hr-attendance',
        components:{
            default: () => import(/* webpackChunkName: "hr-attendance" */ "@/v3/pages/HrAttendance.vue")
        },
        meta:{
            title: 'HR Attendance'
        }
    },
    {
        path: '/hr-weekly',
        components:{
            default: () => import(/* webpackChunkName: "hr-attendance-weekly" */ "@/v3/pages/HrAttendanceWeekly.vue")
        },
        meta:{
            title: 'HR Attendance Weekly'
        }
    },
    {
        path: '/account',
        components:{
            default: () => import(/* webpackChunkName: "account" */ "@/v3/pages/HrCompany.vue")
        },
        meta:{
            title: 'Account'
        }
    },
    {
        path: '/approvals',
        components:{
            default: () => import(/* webpackChunkName: "hr-approvals" */ "@/v3/pages/LeaveRequests.vue")
        },
        meta:{
            title: 'Approvals'
        }
    },
    {
        path:'/documents',
        components:{
            default: () => import(/* webpackChunkName: "hr-documents" */ "@/v3/pages/HrDocuments.vue")
        },
        meta: {
            title: 'Documents'
        }
    },
    {
        path: '/procure-settings',
        components:{
            default: () => import(/* webpackChunkName: "procure-settings" */ "@/v3/pages/ProcurementSettings.vue")
        },
        meta: {
            title: 'Procure Settings'
        }
    },
    {
        path: '/hq-settings',
        components:{
            default: () => import(/* webpackChunkName: "hq-settings" */ "@/v3/pages/HqSettings.vue")
        },
        meta: {
            title: 'HQ Settings'
        }
    },
    {
        path: '/warehouse',
        name: 'Warehouse',
        components:{
            default: () => import(/* webpackChunkName: "hub-viewer" */ "@/v3/pages/HubViewer.vue")
        },
        props:{
            default: route => ({ baseUrl: "https://docs.google.com/spreadsheets/d/e/2PACX-1vSHvb9eyJu7uHywFyfOPOh1NMoFIUZJ6dFmnnDymSId6wYTyhs2aqaDZHL8Isq3qqbO7jTqR50oC-gr/pubhtml?widget=true&amp;headers=false" })

        },
        meta: {
            title: 'Warehouse'
        }
    },
    {
        path: '/users/onboarding/:id',
        components: {
            default: () => import(/* webpackChunkName: "auth-onboarding" */ "@/v3/pages/OnboardingAuthorised.vue")
        },
        meta: {
            title: 'Onboarding'
        }
    },
    {
        path: '*', // or '/index.html'
        beforeEnter: (to, from, next) => {
            next('/')
        }
    }
];


export default routes;
